<template>
    <main>
        <section>
            <div class="side-title">
                <h1 class="side-title">Contact</h1>
            </div>
            <div class="content">
                <div class="mail">
                    <a href="mailto:keemhyunseok@gmail.com">keemhyunseok@gmail.com</a>
                </div>
                <div class="web-address">
                    <a href="https://www.kimhyun-seok.com/" target="_blank"
                        >Artist Official Website</a
                    >
                </div>
                <p class="notice">
                    {{ this.lang == 'Kr' ? this.notice.kr : this.notice.en }}
                </p>
            </div>
        </section>
        <section>
            <div class="side-title">
                <h1>Purchase Enquiry</h1>
            </div>
            <div class="content">
                <form action="">
                    <div class="form-row">
                        <!-- <label for="name">이름 또는 닉네임</label> -->
                        <input
                            type="text"
                            id="name"
                            name="name"
                            :placeholder="
                                this.lang == 'Kr'
                                    ? this.placeHolder.name.kr
                                    : this.placeHolder.name.en
                            "
                            v-model="form.name"
                        />
                    </div>
                    <div class="form-row">
                        <!-- <label for="email">이메일</label> -->
                        <input
                            type="text"
                            id="email"
                            name="email"
                            :placeholder="
                                this.lang == 'Kr'
                                    ? this.placeHolder.address.kr
                                    : this.placeHolder.address.en
                            "
                            v-model="form.email"
                        />
                    </div>
                    <div class="form-row">
                        <!-- <label for="content">내용</label> -->
                        <textarea
                            name="content"
                            id="content"
                            :placeholder="
                                this.lang == 'Kr'
                                    ? this.placeHolder.content.kr
                                    : this.placeHolder.content.en
                            "
                            v-model="form.content"
                        ></textarea>
                    </div>
                    <div class="form-row file-row">
                        <label for="file">{{
                            this.lang == 'Kr' ? this.placeHolder.file.kr : this.placeHolder.file.en
                        }}</label>
                        <p>{{ this.lang == 'Kr' ? this.chooseFile.kr : this.chooseFile.en }}</p>
                        <input type="file" id="file" name="file" @input="changeText" />
                    </div>
                    <input id="submit" type="submit" value="SEND" @click="sendEmail" />
                </form>
            </div>
            <div id="modal" v-show="modal.on">
                <div class="modal__notice">
                    {{ modal.text }}
                </div>
            </div>
        </section>
    </main>
</template>
<script>
export default {
    props: {
        lang: {
            default: 'kr',
        },
    },
    data() {
        return {
            modal: {
                on: false,
                text: `전송중입니다. 잠시만 기다려주세요.`,
            },
            form: {
                name: ``,
                email: ``,
                content: ``,
            },
            chooseFile: {
                kr: `파일을 선택하세요.`,
                en: `Please select an attachment file.`,
            },
            notice: {
                kr: `*** 이 프로젝트에 대한 문의사항은 아래의 폼을 작성하여
                    보내주세요.`,
                en: `*** For inquiries about this project, please fill out the form below and send it to us.`,
            },
            placeHolder: {
                name: {
                    kr: `이름 또는 닉네임`,
                    en: `Name or Nickname`,
                },
                address: {
                    kr: `이메일 주소`,
                    en: `E-mail address`,
                },
                content: {
                    kr: `내용`,
                    en: `Content`,
                },
                file: {
                    kr: `첨부파일`,
                    en: `Attachment`,
                },
            },
        };
    },
    methods: {
        changeText(e) {
            e.target['files'].length
                ? (this.chooseFile = e.target['files'][0]['name'])
                : (this.chooseFile = '파일을 선택하세요');
        },
        sendEmail(e) {
            let ref = this;
            e.preventDefault();
            this.modal.on = !this.modal.on;
            let file = document.querySelector('#file').files[0];
            let namefield = Boolean(document.querySelector('#name').value);
            let email = Boolean(document.querySelector('#email').value);
            let content = Boolean(document.querySelector('#content').value);
            if (namefield && email && content) {
                if (file) {
                    let reader = new FileReader();
                    reader.readAsBinaryString(file);
                    reader.onload = function() {
                        let dataUri = 'data:' + file.type + ';base64,' + btoa(reader.result);
                        ref.$loadScript('https://smtpjs.com/v3/smtp.js')
                            .then(() => {
                                window.Email.send({
                                    Host: 'smtp.gmail.com',
                                    Username: '42mxm.dev@gmail.com',
                                    Password: 'kookmin1213@@',
                                    To: 'keemhyunseok@gmail.com',
                                    From: ref.form.email,
                                    Subject: `${ref.form.name}님으로 부터 ASSY에 대한 문의가 도착했습니다.`,
                                    Body: ref.form.content,
                                    Attachments: [
                                        {
                                            name: file.name,
                                            data: dataUri,
                                        },
                                    ],
                                }).then(function() {
                                    ref.modal.text = `전송이 완료되었습니다.`;
                                    setTimeout(function() {
                                        ref.modal.on = !ref.modal.on;
                                        ref.modal.text = `전송중입니다. 잠시만 기다려주세요.`;
                                    }, 1500);
                                });
                            })
                            .catch((e) => {
                                console.log(e);
                            });
                    };
                } else {
                    ref.$loadScript('https://smtpjs.com/v3/smtp.js')
                        .then(() => {
                            window.Email.send({
                                Host: 'smtp.gmail.com',
                                Username: '42mxm.dev@gmail.com',
                                Password: 'kookmin1213@@',
                                To: 'keemhyunseok@gmail.com',
                                From: ref.form.email,
                                Subject: `${ref.form.name}님으로 부터 ASSY에 대한 문의가 도착했습니다.`,
                                Body: ref.form.content,
                            }).then(function() {
                                ref.modal.text = `전송이 완료되었습니다.`;
                                setTimeout(function() {
                                    ref.modal.on = !ref.modal.on;
                                    ref.modal.text = `전송중입니다. 잠시만 기다려주세요.`;
                                }, 1500);
                            });
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                }
            } else {
                ref.modal.text = `이름 또는 닉네임, 이메일 주소, 내용은 필수로 입력하셔야합니다.`;
                setTimeout(function() {
                    ref.modal.on = !ref.modal.on;
                    ref.modal.text = `전송중입니다. 잠시만 기다려주세요.`;
                }, 1500);
                return;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
main {
    min-height: 100vh;
    section {
        display: flex;
        flex-wrap: wrap;
        padding: 1rem;
        padding-top: 0;
        margin-bottom: 2rem;
        .phone,
        .mail,
        .web-address {
            width: 100%;
            a {
                transition: color 0.2s;
                &:hover {
                    color: lightgray;
                }
            }
        }
        .content {
            display: flex;
            flex-wrap: wrap;
            line-height: 1.6;
            width: 50%;
            p {
                color: lightgray;
            }
            form {
                width: 100%;
            }
        }
    }
}

.form-row {
    margin-bottom: 1rem;
    input,
    textarea {
        // border-bottom: 0;
        font-size: 1rem;
        &::placeholder {
            color: lightgray;
            font-size: 1rem;
        }
    }
    textarea {
        font-size: 1rem;
        min-height: 10rem;
        display: block;
        height: auto;
    }
    #file {
        display: none;
    }
}

.file-row {
    font-size: 1rem;
    border: 2px solid black;
    // border-bottom: 0;
    padding: 0.5rem;
    display: flex;
    position: relative;
    line-height: 1.1;
    label {
        margin-right: 1rem;
        padding: 0.5rem;

        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        &:hover {
            color: rgb(230, 230, 230);
        }
    }
    p {
        padding-left: 8rem;
        font-size: 1rem;
        font-weight: 400;
    }
}

#modal {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    backdrop-filter: blur(3px);
    z-index: 999;
}

#submit {
    background: white;
    cursor: pointer;
    &:hover {
        background: rgb(230, 230, 230);
        color: white;
    }
}

.notice {
    font-weight: 500;
    font-size: 0.9em;
}

@media all and (max-width: 500px) {
    main {
        section {
            .side-title {
                width: 100%;
            }
            .content {
                width: 100%;
            }

            h1 {
                margin-bottom: 0.25rem;
            }
        }
    }
}
</style>
