<template>
    <div>
        <input type="file" value="" @input="controll" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            img_data: ``,
            img: ``,
        };
    },
    methods: {
        controll(e) {
            const el = e.currentTarget;
            const options = {
                type: 'dataURL',
            };
            let file = null;
            this.$html2canvas(el, options).then(res => {
                var inputURL = res;

                var blobObject = blobCreationFromURL(inputURL);

                // Create Blob file from URL
                function blobCreationFromURL(inputURI) {
                    var binaryVal;

                    // mime extension extraction
                    var inputMIME = inputURI
                        .split(',')[0]
                        .split(':')[1]
                        .split(';')[0];

                    // Extract remaining part of URL and convert it to binary value
                    if (inputURI.split(',')[0].indexOf('base64') >= 0) binaryVal = atob(inputURI.split(',')[1]);
                    // Decoding of base64 encoded string
                    else binaryVal = unescape(inputURI.split(',')[1]);

                    // Computation of new string in which hexadecimal
                    // escape sequences are replaced by the character
                    // it represents

                    // Store the bytes of the string to a typed array
                    var blobArray = [];
                    for (var index = 0; index < binaryVal.length; index++) {
                        blobArray.push(binaryVal.charCodeAt(index));
                    }

                    return new Blob([blobArray], {
                        type: inputMIME,
                    });
                }

                var fdataobj = new FormData();

                // Create formdata object and append the object
                // file to the name 'Blob file'
                fdataobj.append('file', blobObject);
                file = fdataobj.get('file');

                const tokenConfig = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        accept: 'application/json',
                    },
                    body: JSON.stringify({
                        username: 'hello',
                        password: 'kookmin1213!',
                    }),
                };
                const formData = new FormData();
                formData.append('file', file);
                formData.append('title', 'Hello World!');
                formData.append('caption', 'Have a wonderful day!');
                // Get token
                fetch(`https://www.assyproject.xyz/assy/wp-json/jwt-auth/v1/token`, tokenConfig)
                    .then(res => res.json())
                    .then(user => {
                        // Post image
                        const mediaConfig = {
                            method: 'POST',
                            headers: {
                                Authorization: 'Bearer ' + user.token,
                            },
                            body: formData,
                        };
                        fetch(`https://www.assyproject.xyz/assy/wp-json/wp/v2/media`, mediaConfig)
                            .then(res => res.json())
                            .then(res => {
                                console.log(res);
                                const postCofig = {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        accept: 'application/json',
                                        Authorization: 'Bearer ' + user.token,
                                    },
                                    body: JSON.stringify({
                                        title: 'test ',
                                        fields: {
                                            playarea_img: [res.id],
                                        },
                                    }),
                                    status: 'publish',
                                };
                                fetch(`https://www.assyproject.xyz/assy/wp-json/wp/v2/posts`, postCofig)
                                    .then(res => res.json())
                                    .then(res => console.log(res));
                            });
                    });
            });
        },
    },
};
</script>

<style></style>
