<template>
    <main>
        <div class="home" v-show="this.lang == 'En'">
            <vue-typed-js
                :strings="['', this.text.en]"
                :showCursor="true"
                :typeSpeed="100"
            >
                <div
                    name=""
                    id=""
                    contentEditable="true"
                    class="left-side"
                    spellcheck="false"
                >
                    <span class="typing "></span>
                </div>
            </vue-typed-js>
            <vue-typed-js
                :strings="['', this.text.en]"
                :showCursor="false"
                :typeSpeed="100"
            >
                <textarea
                    name=""
                    id=""
                    contentEditable="true"
                    class="typing right-side"
                    spellcheck="false"
                ></textarea>
            </vue-typed-js>
        </div>
        <div class="home" v-show="this.lang == 'Kr'">
            <vue-typed-js
                :strings="['', this.text.kr]"
                :showCursor="true"
                :typeSpeed="100"
            >
                <div
                    name=""
                    id=""
                    contentEditable="true"
                    class="left-side"
                    spellcheck="false"
                >
                    <span class="typing "></span>
                </div>
            </vue-typed-js>
            <vue-typed-js
                :strings="['', this.text.kr]"
                :showCursor="false"
                :typeSpeed="100"
            >
                <textarea
                    name=""
                    id=""
                    contentEditable="true"
                    class="typing right-side"
                    spellcheck="false"
                ></textarea>
            </vue-typed-js>
        </div>
    </main>
</template>

<script>
// @ is an alias to /src

export default {
    props: {
        lang: {
            default: 'Kr',
        },
    },
    mounted() {},
    data() {
        return {
            text: {
                kr: `ASSY는 폰트를 사용하여 조합에 따른 이미지의 표현적 가능성을 실험하는 프로젝트입니다. ASSY는 	
'Aseembly'를 줄인 말로써 기계 산업의 '조립품'또는 컴퓨터를 제어할 수 있는 기계어와 대응되는 중간 언어인 '어셈블리어'에서 착안한 이름입니다. ASSY 폰트는 한글2832자, 영어 52자그리고 그 외 44자로 구성된 컬러 폰트로써 글자를 타이핑하면 이미지가 생성됩니다. 컴퓨터 그래픽스에서 주요한 재현적 특징인 'Dither' 알고리듬 패턴과 CGA(Color Graphics Adapter)의 컬러팔레트로 구성되어 있습니다. 이 프로젝트를 통해 이미지의 표현의 변칙적인 매커니즘을 경험할 수 있습니다. 이 웹사이트에서 ASSY 폰트를 사용해 이미지를 생성할수 있으며 사용자는 자유롭게 이미지를 프린트하거나 웹에 저장할 수 있습니다. ASSY 폰트는 크리에이티브 커먼즈 저작자표시-변경금지(CC-BY-ND)를 준수합니다. 이 프로젝트 웹사이트에 저장된 모든 이미지는 퍼블릭 도메인(CC0) 이미지로 전환됨을 전제합니다.`,
                en: `ASSY is a project that experiments with the expressive potential of images according to combinations using fonts. ASSY is an abbreviation of 'Assembly.' Its name is derived from 'assembly' in the machinery industry or 'assembly language,' an intermediate language that corresponds to machine language that can control computers. ASSY Font is a color font that consists of 2832 Hangeul (Korean letters), 52 English alphabets, and 44 other letters. When you type a letter, an image is generated. It consists of the 'Dither' algorithm pattern, which is a major reproducible characteristic in computer graphics, and the color palette of CGA (Color Graphics Adapter). This project will enable you to experience the anomalous mechanism of image expression. Try to use ASSY Font on this website to create images. Users can freely print images out or save them to the web.

*ASSY Font complies with Creative Commons Attribution-No Derivative (CC-BY-ND).
All images stored on this project website are subject to conversion to public domain (CC0) images.`,
            },
        };
    },
};
</script>

<style lang="scss" scoped>
.home {
    display: flex;
    .typed-element {
        width: 50%;
        align-items: flex-start;
        font-size: 2.8rem;
        word-break: break-all;
    }

    .left-side {
        padding: 0 1rem;
        font-size: 2rem;
        line-height: 1.25;
        &:focus {
            outline: none;
        }
    }

    .right-side {
        border: 0;
        min-height: 100vh;
        font-family: assy;
        line-height: 0.705;
        font-size: 3rem;
        padding: 0 1rem 0 0;
        word-break: break-all;
    }
}

@media screen and (max-width: 500px) {
    .home {
        flex-direction: column;
        .typed-element {
            width: 100%;
        }
        .right-side {
            padding-left: 1rem;
            padding-right: 0.5rem;
        }
        .left-side {
            padding-bottom: 1rem;
        }
    }
}
</style>
