<template>
    <main class="archive-container">
        <template v-if="loading">
            {{ this.lang == 'Kr' ? '로딩중입니다...' : 'Loading...' }}
        </template>
        <template v-else>
            <div
                v-for="archive in archives"
                v-bind:key="archives[archive]"
                class="archive-box"
            >
                <img
                    :src="archive.acf.playarea_img[0].sizes.large"
                    alt=""
                    @click="
                        modalControll(
                            archive.acf.playarea_img[0].sizes.large,
                            archive.acf['font-size'],
                            archive.acf['text-align'],
                            archive.title.rendered,
                            archive.acf.name,
                            archive.acf.introduction
                        )
                    "
                    class="archive-img"
                />
                <!-- <textarea
                name=""
                id=""
                v-model="archive.acf.playarea"
                v-bind:style="{
                    fontSize: archive.acf['font-size'] * 0.6318 + 'rem',
                    textAlign: archive.acf['text-align'],
                }"
                spellcheck="false"
                readonly
            ></textarea> -->
            </div>
            <div v-show="this.modal.on" id="modal">
                <inline-svg
                    :src="require('../assets/modal-exit.svg')"
                    @click="modalControll"
                    class="modal-exit"
                ></inline-svg>
                <div class="playarea-container">
                    <img
                        :src="modal.playArea"
                        alt=""
                        class="modal-playarea-img"
                    />
                </div>

                <div class="vertical">
                    <div class="modal-title">{{ this.modal.title }}</div>
                    <div>
                        <div class="modal-name">{{ this.modal.name }}</div>
                        <div class="modal-introduction">
                            {{ this.modal.introduction }}
                        </div>
                    </div>
                </div>
                <div class="container-for-mobile">
                    <div class="controller">
                        <!-- <inline-svg :src="require('../assets/download.svg')"></inline-svg> -->
                        <inline-svg
                            :src="require('../assets/print.svg')"
                            @click="print"
                        ></inline-svg>
                    </div>
                    <div class="modal-copyright">
                        <img src="../assets/cc-zero.png" alt="" />
                    </div>
                </div>
            </div>
        </template>
    </main>
</template>

<script>
let server = `https://www.assyproject.xyz/assy`;

export default {
    props: {
        lang: {
            default: 'Kr',
        },
    },

    data() {
        return {
            archives: '',
            modal: {
                on: false,
                playArea: '',
                title: '',
                textAlign: '',
                name: '',
                introduction: '',
            },
            output: null,
            loading: false,
        };
    },
    created() {
        this.loading = true;
        fetch(`${server}/wp-json/wp/v2/posts?per_page=100`, {
            method: 'GET',
        })
            .then(function(response) {
                return response.json();
            })
            .then((res) => {
                this.archives = res;
                this.loading = false;
            });
    },
    methods: {
        modalControll(
            playArea,
            fontSize,
            textAlign,
            title,
            name,
            introduction
        ) {
            this.modal.on = !this.modal.on;
            this.modal.playArea = playArea;
            this.modal.fontSize = fontSize;
            this.modal.textAlign = textAlign;
            this.modal.title = title;
            this.modal.name = name;
            this.modal.introduction = introduction;
        },
        print() {
            const target = document.querySelector('.modal-playarea-img');
            const printStyle = `@media print {
                        @page{
                            margin: 10px;
                             *{
                                box-sizing:border-box;
                            }
                        }
                    }
                    img{
                        display:block;
                        margin: 0 auto;
                        width: 100%;
                    }
                    body{
                        text-align:center;
                        display:flex;
                        align-items: flex-start;
                    }`;

            let mywindow = window.open('', 'PRINT', '');
            function printWindow() {
                mywindow.document.write(
                    `<html><head><title>ASSY PRINT</title><style>${printStyle}</style><body>${target.outerHTML}</head></body></html>`
                );
                mywindow.document.close();
                mywindow.focus();
                mywindow.print();
                mywindow.close();
            }

            printWindow();
        },
    },
    watch: {
        modal: {
            deep: true,
            handler(newData) {
                if (newData.on) {
                    document.querySelector('body').style.overflow = 'hidden';
                } else {
                    document.querySelector('body').style.overflow = 'scroll';
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
main {
    min-height: 100vh;
}
textarea {
    word-break: break-all;
}
.archive-container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.8rem;
    column-gap: 1.25%;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
}

.archive-box {
    width: 32.5%;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .archive-img {
        width: 100%;
        cursor: pointer;
        border: 2px solid black;
        transition: filter 0.2s;
        display: block;
        &:hover {
            filter: brightness(0.7);
        }
    }
}

#modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(3px);
    z-index: 999;
    padding: 1rem;
    overflow: scroll;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;

    .modal-exit {
        width: 2.3rem;
        position: fixed;
        top: 1rem;
        left: 1rem;
        cursor: pointer;
        &:hover {
            fill: lightgray;
        }
    }
    .modal-playarea {
        font-family: assy;
        line-height: 0.7;
        width: 37vw;
        height: 52.328571428571429vw;
        padding: 0;
        border: 0;
        background: white;
    }
    .vertical {
        margin-left: 1rem;
        width: 32%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .modal-title {
        font-size: 1.1rem;
        margin-bottom: 0.5rem;
        border: 2px solid black;
        padding: 0.5rem;
        background: white;
        text-align: center;
    }
    .modal-name {
        font-size: 1rem;
        margin-bottom: 0.5rem;
        border: 2px solid black;
        background: white;
        padding: 0.5rem;
        text-align: left;
    }

    .modal-introduction {
        font-size: 0.9em;
        line-height: 1.45;
        font-weight: 500;
        border: 2px solid black;
        background: white;
        padding: 0.5rem;
    }

    .modal-copyright {
        width: 8%;
        border: 2px solid black;
        position: fixed;
        top: 1rem;
        right: 1rem;
        img {
            width: 100%;
            display: block;
        }
    }
}

.controller {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    svg {
        width: 4.5rem;
        margin-left: 0.5rem;
        cursor: pointer;
        &:hover {
            fill: lightgray;
        }
    }
}

.playarea-container {
    border: 2px solid black;
    width: 38%;
    display: flex;
    img {
        width: 100%;
    }
}

::-webkit-scrollbar {
    display: none;
}

@media print {
    @page {
        margin: 0;
    }

    .modal-exit,
    .vertical,
    .controller {
        display: none;
    }

    .modal-playarea {
        border: 0 !important;
    }
}

@media screen and (max-width: 500px) {
    .archive-container {
        padding-bottom: 2rem;
    }
    .archive-box {
        width: 100%;
        margin-bottom: 1rem;
    }

    #modal {
        padding-top: 4rem;
        align-items: flex-start;
        .modal-exit {
            left: 50%;
            transform: translateX(-50%);
        }
        .playarea-container {
            width: 100%;
            height: 32.5275rem;
        }
        .vertical {
            margin-left: 0;
            margin-top: 1rem;
            width: 100%;
            height: auto;
            .modal-title {
                margin-bottom: 1rem;
            }
            .modal-name {
                margin-bottom: 1rem;
            }
        }
        .modal-copyright {
            position: static;
        }
        .controller {
            position: static;
        }

        .container-for-mobile {
            margin-top: 1rem;
            height: 15vw;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: flex-end;
            .modal-copyright {
                width: 30%;
            }
            .controller {
                width: 40%;
                height: 100%;
                text-align: center;
                border-radius: 100px;
                background: black;
                svg {
                    height: 100%;
                    margin-left: 0;
                }
            }
        }
    }
}
</style>
