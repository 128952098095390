<template>
    <div id="app">
        <div id="nav" v-if="!ismobile">
            <router-link :to="{ name: 'Home', params: { lang, ismobile } }">ASSY</router-link>
            <router-link :to="{ name: 'Tutorial', params: { lang, ismobile } }">Tutorial</router-link>
            <router-link :to="{ name: 'Playassy', params: { lang, ismobile } }">Play ASSY</router-link>
            <router-link :to="{ name: 'Archive', params: { lang, ismobile } }">Archive</router-link>
            <router-link :to="{ name: 'Contact', params: { lang, ismobile } }">Contact</router-link>
        </div>
        <!-- 모바일네비게이션 시작 -->
        <div id="mobile-nav" v-if="ismobile">
            <div class="nav-title">
                ASSY
            </div>
            <div class="button-container">
                <div id="lang-button" v-on:click="this.changeLang" v-if="ismobile">
                    {{ lang }}
                </div>
                <inline-svg :src="require('./assets/mobile-menu-button.svg')" class="mobile-menu-button" @click="menuControll"></inline-svg>
            </div>
        </div>
        <!-- 모바일 네비게이션 끝 -->
        <div id="lang-button" v-on:click="this.changeLang" v-if="!ismobile">
            {{ lang }}
        </div>
        <!-- <transition name="fade"> -->
        <router-view />
        <!-- </transition> -->
        <div id="key-map-button" @click="modalControll">
            <inline-svg class="svg-keymap" :src="require('./assets/key-map.svg')"></inline-svg>
        </div>
        <transition name="fade-fast">
            <div id="key-map-modal" v-show="modal.on">
                <inline-svg :src="require('./assets/modal-exit.svg')" @click="modalControll" class="modal-exit"></inline-svg>
                <inline-svg class="key-map-img" :src="require('./assets/keymap-modal-new.svg')"></inline-svg>
            </div>
        </transition>

        <div id="menu-modal" v-show="menu.on">
            <inline-svg :src="require('./assets/modal-exit.svg')" class="modal-exit" @click="menuControll"></inline-svg>
            <ul>
                <router-link :to="{ name: 'Home', params: { lang, ismobile } }" @click.native="menuControll">ASSY</router-link>
                <router-link :to="{ name: 'Tutorial', params: { lang, ismobile } }" @click.native="menuControll">Tutorial</router-link>
                <router-link :to="{ name: 'Playassy', params: { lang, ismobile } }" @click.native="menuControll">Play ASSY</router-link>
                <router-link :to="{ name: 'Archive', params: { lang, ismobile } }" @click.native="menuControll">Archive</router-link>
                <router-link :to="{ name: 'Contact', params: { lang, ismobile } }" @click.native="menuControll">Contact</router-link>
            </ul>
        </div>
        <footer>
            <div>
                Web Development <span class="thin"><a target="_blank" href="https://www.instagram.com/42mxm">42mxm</a></span
                ><br />
                Creative Commons License BY-ND. 2021. <span class="thin">Hyun-seok Kim.</span>
            </div>
        </footer>
    </div>
</template>
<script>
export default {
    data() {
        return {
            lang: 'Kr',
            toggleKeymap: true,
            modal: {
                on: false,
            },
            ismobile: false,
            menu: {
                on: false,
            },
        };
    },
    mounted() {
        let ref = this;
        // 모바일인지 웹인지 사이즈 구별하기
        function sizeDistinct() {
            if (window.innerWidth <= 500) {
                ref.ismobile = true;
            } else {
                ref.ismobile = false;
            }
        }

        window.addEventListener('resize', sizeDistinct);
        sizeDistinct();
    },
    methods: {
        changeLang() {
            this.lang == 'Kr' ? (this.lang = 'En') : (this.lang = 'Kr');

            this.$route.params.lang = this.lang;
        },
        modalControll() {
            this.modal.on = !this.modal.on;
        },
        menuControll() {
            this.menu.on = !this.menu.on;
        },
    },
    watch: {
        modal: {
            deep: true,
            handler(newData) {
                if (newData.on) {
                    document.querySelector('body').style.overflow = 'hidden';
                } else {
                    document.querySelector('body').style.overflow = 'scroll';
                }
            },
        },
        ismobile(newone) {
            this.$route.params.ismobile = newone;
        },
    },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@400;500;600;700&display=swap');

* {
    box-sizing: border-box;
}
a {
    color: inherit;
    text-decoration: none;
}

input,
textarea {
    -webkit-appearance: none;
    border-radius: 0;
    box-shadow: none;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
    // padding-top: 4rem;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

@font-face {
    font-family: 'assy';
    src: url('./assets/assyfont.woff');
}

html {
    font-size: 1.4vw;
    font-family: 'Gothic A1', helvetica, sans-serif;
    font-weight: 600;
    // word-break: keep-all;
}

// #nav {
//     padding: 30px;

//     a {
//         font-weight: bold;
//         color: #2c3e50;

//         &.router-link-exact-active {
//             color: #42b983;
//         }
//     }
// }

textarea {
    width: 100%;
    border: 2px solid black;
    resize: none;
    padding: 0.5rem;
    font-family: helvetica;

    &:focus {
        outline: none;
    }
}

input {
    width: 100%;
    border: 2px solid black;
    resize: none;
    padding: 0.5rem;
    font-size: 1.3rem;
    line-height: 1;

    &:focus {
        outline: none;
    }
}

// Navigation
#nav {
    position: fixed;
    top: 1rem;
    left: 1rem;
    display: flex;
    margin: 0;
    z-index: 99;
    a {
        margin-right: 0.5rem;
        height: auto;
        border: 2px solid black;
        font-weight: 700;
        font-size: 1.2rem;
        display: flex;
        background: white;
        line-height: 1;
        color: inherit;
        padding: 0.4rem 0.5rem 0.25rem;
        padding-top: 0.4rem;
        text-decoration: none;
        transition: background 0.2s, color 0.2s;
        &.router-link-exact-active {
            background: black;
            color: white;
        }
        &:hover {
            background: rgb(230, 230, 230);
            color: white;
            cursor: pointer;
        }
    }
}

// LangBtn
#lang-button {
    position: fixed;
    top: 1rem;
    right: 1rem;
    display: flex;
    font-weight: 600;
    font-size: 1.2rem;
    display: flex;
    border: 2px solid black;
    padding: 0.25rem 0.5rem;
    padding-top: 0.4rem;
    cursor: pointer;
    background: white;
    z-index: 99;
    &:hover {
        background: rgb(230, 230, 230);
        color: white;
    }
}

// Footer css
#key-map-button {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    width: 7rem;
    cursor: pointer;
    z-index: 99;
    svg {
        transition: fill 0.25s;
        //     width: 100%;
        //     // .svg-keymap {
        //     //     fill: white;
        //     //     transition: fill 0.25s;
        //     // }

        //     // .svg-white {
        //     //     fill: black;
        //     // }
        &:hover {
            fill: rgb(150, 150, 150);
        }
    }
}

.side-title {
    width: 15%;
    h1 {
        font-size: 1.2rem;
        padding-right: 2rem;
        line-height: 1.2;
        font-weight: 700;
    }
}

/* 타이밍 기능을 사용할 수 있습니다. */
.slide-fade-enter-active {
    transition: all 1s ease;
}
.slide-fade-leave-active {
    transition: all 1s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(50px);
    opacity: 0;
}

// ::-webkit-scrollbar {
//     display: none;
// }

::selection {
    background: transparent;
    color: rgba(0, 0, 0, 0.3);
}

.modal-exit {
    width: 2.3rem;
    position: fixed;
    top: 1rem;
    left: 1rem;
    cursor: pointer;
    transition: fill 0.25s;
    &:hover {
        fill: lightgray;
    }
}

#key-map-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(9px);
    z-index: 999;
    padding: 1rem;
    overflow: scroll;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.key-map-img {
    width: 65%;
}

footer {
    font-size: 0.7em;
    padding: 0.7rem 1rem;
    background: rgb(230, 230, 230);
    text-align: center;
    display: flex;
    justify-content: center;
    line-height: 1.4;
    color: black;
    .thin {
        font-weight: 500;
    }
}

main {
    padding-top: 4rem;
}

@media screen and (max-width: 500px) {
    html {
        font-size: 4vw;
    }
    main {
        padding-top: 5rem;
    }

    #key-map-button {
        display: none;
    }

    #mobile-nav {
        top: 0;
        left: 0;
        position: fixed;
        width: 100vw;
        padding: 1rem;
        font-size: 1.5rem;
        border-bottom: 2px solid black;
        font-weight: 700;
        background: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 99;
        height: 15vw;

        .nav-title {
            font-size: 1.75rem;
            line-height: 1;
            display: inline-block;
            padding-top: 0.3rem;
        }

        #lang-button {
            position: static;
            padding: 0.25rem 0.75rem;
            padding-top: 0.3rem;
            &:hover {
                background: transparent;
                color: black;
            }
        }
        .button-container {
            display: flex;
            width: 40%;
            align-items: center;
            .mobile-menu-button {
                margin-left: 1.5rem;
                width: 40%;
                height: 100%;
            }
        }
    }

    #menu-modal {
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        position: fixed;
        background: rgba(0, 0, 0, 0.85);
        backdrop-filter: blur(3px);
        padding: 3rem;
        display: flex;
        align-items: center;
        z-index: 999;
        .modal-exit {
            left: 50%;
            transform: translateX(-50%);
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            a {
                font-size: 1.5rem;
                background: white;
                border: 2px solid black;
                padding: 0.5rem;
                padding-top: 0.5rem;
                padding-bottom: 0.25rem;
                margin: 0.25rem;
            }
        }

        .router-link-exact-active {
            background: black;
            color: white;
        }
    }
}

.fade-enter-active {
    transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.fade-fast-enter-active {
    transition: opacity 0.5s;
}

.fade-fast-enter,
.fade-fast-leave-to {
    opacity: 0;
}
</style>
