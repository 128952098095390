import Vue from 'vue';
import VueRouter from 'vue-router';
import '@babel/polyfill';

import Home from '../views/Home.vue';
import Tutorial from '../views/Tutorial.vue';
import PlayAssy from '../views/PlayAssy.vue';
import Archive from '../views/Archive.vue';
import Contact from '../views/Contact.vue';
import Upload from '../views/Upload.vue';

import InlineSvg from 'vue-inline-svg';
import VueTypedJs from 'vue-typed-js';
import LoadScript from 'vue-plugin-load-script';
import VueHtml2Canvas from 'vue-html2canvas';

// import VueFullPage from 'vue-fullpage.js';

// Vue.use(VueFullPage);
Vue.use(LoadScript);
Vue.use(VueRouter);
Vue.component('inline-svg', InlineSvg);
Vue.use(VueTypedJs);
Vue.use(VueHtml2Canvas);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        props: true,
    },
    {
        path: '/tutorial',
        name: 'Tutorial',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        component: Tutorial,
        props: true,
    },
    {
        path: '/play-assy',
        name: 'Playassy',
        component: PlayAssy,
        props: true,
    },
    {
        path: '/archive',
        name: 'Archive',
        component: Archive,
        props: true,
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
        props: true,
    },
    {
        path: '/upload',
        name: 'Upload',
        component: Upload,
        props: true,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

export default router;
