<template>
    <main>
        <form v-on:submit.prevent="submitForm" id="play">
            <div class="border">
                <div ref="printMe" id="printMe">
                    <textarea class="play-area" placeholder="Test Assy fonts" id="playArea" spellcheck="false" v-bind:style="textareaStyle" @input="getArea"></textarea>
                </div>
            </div>

            <input class="title" id="title" placeholder="Title" v-model="form.title" spellcheck="false" />
            <input class="name" id="name" placeholder="Name" v-model="form.name" spellcheck="false" />
            <textarea class="introduction" id="introduction" placeholder="Introduction" v-model="form.introduction" spellcheck="false" />
        </form>
        <div id="controller">
            <div class="text-align-controller">
                <inline-svg :src="require('../assets/left-align.svg')" @click="changeTextAlign"></inline-svg>
                <inline-svg :src="require('../assets/center-align.svg')" @click="changeTextAlign"></inline-svg>
                <inline-svg :src="require('../assets/right-align.svg')" @click="changeTextAlign"></inline-svg>
            </div>
            <input class="font-size-controller" type="range" min="0.7" max="3" step="0.05" value="1" @input="changeFontSize" />

            <div class="download-print-controller" v-if="!this.ismobile">
                <inline-svg :src="require('../assets/upload.svg')" @click="submitForm"></inline-svg>
                <!-- <inline-svg :src="require('../assets/download.svg')"></inline-svg> -->
                <inline-svg :src="require('../assets/print.svg')" @click="print"></inline-svg>
            </div>
        </div>
        <div id="load" v-show="load.on">
            {{ load.text }}
        </div>
        <div class="download-print-controller" v-if="this.ismobile">
            <inline-svg :src="require('../assets/upload.svg')" @click="submitForm"></inline-svg>
            <!-- <inline-svg :src="require('../assets/download.svg')"></inline-svg> -->
            <inline-svg :src="require('../assets/print.svg')" @click="print"></inline-svg>
        </div>
    </main>
</template>
<script>
export default {
    props: {
        lang: {
            default: 'kr',
        },
        ismobile: {
            default: false,
        },
    },

    data() {
        return {
            textareaStyle: {
                textAlign: 'left',
                fontSize: '1rem',
            },
            form: {
                title: '',
                name: '',
                introduction: '',
                playArea: '',
                fontSize: '1.4vw',
                textAlign: 'left',
                p_img: '',
            },
            load: {
                on: false,
                text: '업로드중입니다.',
            },
        };
    },
    methods: {
        changeTextAlign(e) {
            if (e.currentTarget.classList.contains('left-align')) {
                this.textareaStyle.textAlign = 'left';
                this.form.textAlign = 'left';
            } else if (e.currentTarget.classList.contains('center-align')) {
                this.textareaStyle.textAlign = 'center';
                this.form.textAlign = 'center';
            } else if (e.currentTarget.classList.contains('right-align')) {
                this.textareaStyle.textAlign = 'right';
                this.form.textAlign = 'right';
            }
        },
        changeFontSize(e) {
            let fontSize = e.currentTarget.value + 'rem';
            this.textareaStyle.fontSize = fontSize;
            this.form.fontSize = e.currentTarget.value * 1.4 + 'vw';
        },
        submitForm() {
            let ref = this;
            if (Boolean(ref.form.title) && Boolean(ref.form.name) && Boolean(ref.form.introduction) && Boolean(ref.form.playArea)) {
                ref.load.on = !ref.load.on;

                const el = document.querySelector('#printMe');
                const options = {
                    scale: 4,
                    type: 'dataURL',
                };
                let file = null;

                this.$html2canvas(el, options).then(res => {
                    let inputURL = res;
                    ref.output = res;
                    let blobObject = blobCreationFromURL(inputURL);
                    // Create Blob file from URL
                    function blobCreationFromURL(inputURI) {
                        let binaryVal;

                        // Extract remaining part of URL and convert it to binary value
                        if (inputURI.split(',')[0].indexOf('base64') >= 0) binaryVal = atob(inputURI.split(',')[1]);
                        // Decoding of base64 encoded string
                        else binaryVal = unescape(inputURI.split(',')[1]);

                        // Computation of new string in which hexadecimal
                        // escape sequences are replaced by the character
                        // it represents

                        // Store the bytes of the string to a typed array
                        var blobArray = [];
                        for (var index = 0; index < binaryVal.length; index++) {
                            blobArray.push(binaryVal.charCodeAt(index));
                        }

                        var byteArray = new Uint8Array(blobArray);
                        var blob = new Blob([byteArray], {
                            type: 'image/png',
                        });

                        return blob;
                    }

                    var fdataobj = new FormData();

                    // Create formdata object and append the object
                    // file to the name 'Blob file'
                    fdataobj.append('file', blobObject);
                    file = fdataobj.get('file');

                    const tokenConfig = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            accept: 'application/json',
                        },
                        body: JSON.stringify({
                            username: 'hello',
                            password: 'kookmin1213!',
                        }),
                    };
                    const formData = new FormData();
                    formData.append('file', file);
                    formData.append('title', ref.form.title);
                    formData.append('caption', ref.form.introduction);
                    // Get token
                    fetch(`https://www.assyproject.xyz/assy/wp-json/jwt-auth/v1/token`, tokenConfig)
                        .then(res => res.json())
                        .then(user => {
                            // Post image
                            const mediaConfig = {
                                method: 'POST',
                                headers: {
                                    Authorization: 'Bearer ' + user.token,
                                },
                                body: formData,
                            };
                            fetch(`https://www.assyproject.xyz/assy/wp-json/wp/v2/media`, mediaConfig)
                                .then(res => res.json())
                                .then(res => {
                                    console.log(res);
                                    console.log();
                                    const postCofig = {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            accept: 'application/json',
                                            Authorization: 'Bearer ' + user.token,
                                        },
                                        body: JSON.stringify({
                                            title: ref.form.title,

                                            fields: {
                                                name: ref.form.name,
                                                introduction: ref.form.introduction,
                                                playarea: ref.form.playArea,
                                                fontsize: ref.form.fontSize,
                                                textalign: ref.form.textAlign,
                                                playarea_img: [res.id],
                                            },
                                            status: 'publish',
                                        }),
                                    };
                                    fetch(`https://www.assyproject.xyz/assy/wp-json/wp/v2/posts`, postCofig)
                                        .then(res => res.json())
                                        .then(res => {
                                            console.log(res);
                                            ref.load.text = 'Archive에 업로드 되었습니다.';
                                            setTimeout(function() {
                                                ref.load.on = !ref.load.on;
                                                ref.load.text = '업로드중입니다.';
                                                ref.form.title = '';
                                                ref.form.name = '';
                                                ref.form.introduction = '';
                                                // ref.form.playArea = '';
                                                ref.form.p_img = '';
                                                document.querySelector('#playArea').value = '';
                                            }, 1500);
                                        });
                                });
                        });
                });
            } else {
                ref.load.text = '빈칸을 채워주세요.';
                ref.load.on = !ref.load.on;
                setTimeout(function() {
                    ref.load.on = !ref.load.on;
                    ref.load.text = '업로드중입니다.';
                }, 1500);
            }
        },
        getArea(e) {
            this.form.playArea = e.currentTarget.value;
        },
        print() {
            const el = this.$refs.printMe;
            // add option type to get the image version
            // if not provided the promise will return
            // the canvas.
            const options = {
                type: 'dataURL',
            };

            const target = document.querySelector('#playArea');
            // Show all content from playassy textarea
            target.style.height = `${target.scrollHeight}px`;

            this.$html2canvas(el, options).then(res => {
                let mywindow = window.open('', 'PRINT', '');

                let printStyle = `@media print {
                        @page{
                            margin: 10px;
                             *{
                                box-sizing:border-box;
                            }
                        }
                    }
                    img{
                        display:block;
                        margin: 0 auto;
                        width: 100%;
                    }
                    body{
                        text-align:center;
                        display:flex;
                        align-items: flex-start;
                    }`;
                let img = document.createElement('img');
                img.src = res;

                async function printWindow() {
                    await (function open() {
                        mywindow.document.write(`<html><head><title>ASSY PRINT</title><style>${printStyle}</style><body>${img.outerHTML}</head></body></html>`);
                        mywindow.document.close();
                        mywindow.focus();
                    })();

                    await (function close() {
                        mywindow.print();
                        target.style.height = `70.7142857143vw`;
                        mywindow.close();
                    })();
                }

                printWindow();
            });
        },
    },
};
</script>

<style lang="scss" scoped>
main {
    text-align: center;
    margin-bottom: 1rem;
    form {
        width: 50%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        textarea {
            height: auto;
            padding: 0;

            &::placeholder {
                font-family: helvetica;
                padding: 0.5rem;
                font-size: 1rem;
            }
        }

        input {
            margin-bottom: 1rem;
            &::placeholder {
                font-family: helvetica;
                font-size: 1rem;
            }
        }

        #introduction {
            padding: 0.5rem;
            font-size: 1.3rem;
            min-height: 10rem;
            margin-bottom: 1rem;
            &::placeholder {
                padding: 0;
                font-size: 1rem;
                line-height: 1.3;
            }
        }
    }
}

.left-align {
    text-align: left;
}
.right-align {
    text-align: right;
}
.center-align {
    text-align: center;
}

.play-area {
    min-height: 70.714285714285714vw;
    font-family: assy;
    font-size: 1rem;
    font-weight: 500;
    line-height: 0.7;
    &::-webkit-scrollbar {
        display: none;
    }
}

#playArea {
    border: 0;
    word-break: break-all;
}

#controller {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: 9rem;
    display: flex;
    flex-direction: column;

    .text-align-controller {
        display: flex;
        justify-content: space-between;
        // margin-bottom: 0.5rem;
        height: 3rem;
        svg {
            width: 28%;
            cursor: pointer;
            &:hover {
                fill: lightgray;
            }
        }
    }

    .download-print-controller {
        width: 100%;
        display: flex;
        height: 4.5rem;
        justify-content: space-between;
        svg {
            width: 47%;
            transition: transform 0.2s;
            cursor: pointer;
            &:hover {
                fill: lightgray;
            }
        }
    }
}

.font-size-controller {
    appearance: none;
    outline: none;
    padding: 0;
    background: black;
    border-radius: 100px;
    border: 0;
    margin: 0.75rem 0;
    padding: 0.3rem 0.3rem;
    transition: background 0.2s, transform 0.2s;
    cursor: pointer;
    &::-webkit-slider-thumb {
        appearance: none;
        width: 1.5rem;
        height: 0.5rem;
        border-radius: 100px;
        background: white;
        transition: background 0.2s;
    }
    // &:focus {
    //     // transform: rotate(-2deg);
    // }
}

#load {
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(3px);
    color: white;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
}

.border {
    border: 2px solid black;
    margin-bottom: 1rem;
}

@media screen and (max-width: 500px) {
    main {
        padding: 0 1rem;
        padding-top: 9rem;
        form {
            width: 100%;
        }
    }

    #playArea {
        min-height: 130.114vw;
    }

    #controller {
        top: 3.8rem;
        bottom: auto;
        left: 0;
        background: white;
        border-bottom: 2px solid black;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0.5rem 1rem;

        align-items: center;
        height: 4rem;

        .text-align-controller {
            width: 40%;
            height: 100%;
            padding-right: 2rem;
        }

        .font-size-controller {
            width: 60%;
            margin: 0;
        }
    }

    .download-print-controller {
        display: flex;
        height: 12vw;
        justify-content: space-between;
        margin-top: 1rem;
        margin-bottom: 2rem;
        svg {
            width: 45%;
            border-radius: 100px;
            background: black;
        }
    }
}
</style>
