var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"archive-container"},[(_vm.loading)?[_vm._v(" "+_vm._s(this.lang == 'Kr' ? '로딩중입니다...' : 'Loading...')+" ")]:[_vm._l((_vm.archives),function(archive){return _c('div',{key:_vm.archives[archive],staticClass:"archive-box"},[_c('img',{staticClass:"archive-img",attrs:{"src":archive.acf.playarea_img[0].sizes.large,"alt":""},on:{"click":function($event){return _vm.modalControll(
                        archive.acf.playarea_img[0].sizes.large,
                        archive.acf['font-size'],
                        archive.acf['text-align'],
                        archive.title.rendered,
                        archive.acf.name,
                        archive.acf.introduction
                    )}}})])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(this.modal.on),expression:"this.modal.on"}],attrs:{"id":"modal"}},[_c('inline-svg',{staticClass:"modal-exit",attrs:{"src":require('../assets/modal-exit.svg')},on:{"click":_vm.modalControll}}),_c('div',{staticClass:"playarea-container"},[_c('img',{staticClass:"modal-playarea-img",attrs:{"src":_vm.modal.playArea,"alt":""}})]),_c('div',{staticClass:"vertical"},[_c('div',{staticClass:"modal-title"},[_vm._v(_vm._s(this.modal.title))]),_c('div',[_c('div',{staticClass:"modal-name"},[_vm._v(_vm._s(this.modal.name))]),_c('div',{staticClass:"modal-introduction"},[_vm._v(" "+_vm._s(this.modal.introduction)+" ")])])]),_c('div',{staticClass:"container-for-mobile"},[_c('div',{staticClass:"controller"},[_c('inline-svg',{attrs:{"src":require('../assets/print.svg')},on:{"click":_vm.print}})],1),_vm._m(0)])],1)]],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-copyright"},[_c('img',{attrs:{"src":require("../assets/cc-zero.png"),"alt":""}})])}]

export { render, staticRenderFns }