<template>
    <main>
        <section class="section">
            <div class="side-title">
                <h1>Tutorial ➊</h1>
            </div>
            <div class="tutorial-content">
                <div class="directive">
                    <h2>
                        {{
                            this.lang == 'Kr'
                                ? tutorial1.directive.h2.kr
                                : tutorial1.directive.h2.en
                        }}
                    </h2>
                    <h6>
                        {{
                            this.lang == 'Kr'
                                ? tutorial1.directive.h6.kr
                                : tutorial1.directive.h6.en
                        }}
                    </h6>
                </div>
                <div class="experience">
                    <div class="experience__module">
                        <h3>
                            {{
                                this.lang == 'Kr'
                                    ? tutorial1.experience.one.kr
                                    : tutorial1.experience.one.en
                            }}
                        </h3>
                        <div class="textarea-container">
                            <textarea spellcheck="false"> </textarea>
                            <textarea class="placeholder">hello world</textarea>
                        </div>
                    </div>
                    <div class="experience__module">
                        <h3>
                            {{
                                this.lang == 'Kr'
                                    ? tutorial1.experience.two.kr
                                    : tutorial1.experience.two.en
                            }}
                        </h3>
                        <div class="textarea-container">
                            <textarea spellcheck="false"> </textarea>
                            <textarea class="placeholder">WITH ASSY</textarea>
                        </div>
                    </div>
                    <div class="experience__module ">
                        <h3>
                            {{
                                this.lang == 'Kr'
                                    ? tutorial1.experience.three.kr
                                    : tutorial1.experience.three.en
                            }}
                        </h3>
                        <div class="textarea-container long">
                            <textarea spellcheck="false"> </textarea>
                            <textarea class="placeholder">
abcdefghijklmnopqrstuvwxyz</textarea
                            >
                        </div>
                    </div>
                    <div class="experience__module ">
                        <h3>
                            {{
                                this.lang == 'Kr'
                                    ? tutorial1.experience.four.kr
                                    : tutorial1.experience.four.en
                            }}
                        </h3>
                        <div class="textarea-container long">
                            <textarea spellcheck="false"> </textarea>
                            <textarea class="placeholder">
ABCDEFGHIJKLMNOPQRSTUVWXYZ</textarea
                            >
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section">
            <div class="side-title">
                <h1>Tutorial ❷</h1>
            </div>
            <div class="tutorial-content">
                <div class="directive">
                    <h2>
                        {{
                            this.lang == 'Kr'
                                ? tutorial2.directive.h2.kr
                                : tutorial2.directive.h2.en
                        }}
                    </h2>
                    <h6>
                        {{
                            this.lang == 'Kr'
                                ? tutorial2.directive.h6.kr
                                : tutorial2.directive.h6.en
                        }}
                    </h6>
                </div>
                <div class="experience">
                    <div class="experience__module">
                        <h3>
                            {{
                                this.lang == 'Kr'
                                    ? tutorial2.experience.one.kr
                                    : tutorial2.experience.one.en
                            }}
                        </h3>
                        <div class="textarea-container">
                            <textarea spellcheck="false" class="kor"></textarea>
                            <textarea class="placeholder kor">
ㅇㅁㅈㅍㅌ</textarea
                            >
                        </div>
                    </div>
                    <div class="experience__module">
                        <h3>
                            {{
                                this.lang == 'Kr'
                                    ? tutorial2.experience.two.kr
                                    : tutorial2.experience.two.en
                            }}
                        </h3>
                        <div class="textarea-container">
                            <textarea spellcheck="false" class="kor"></textarea>
                            <textarea class="placeholder kor">
ㅣㅣㅣㅗㅡ</textarea
                            >
                        </div>
                    </div>
                    <div class="experience__module long">
                        <h3>
                            {{
                                this.lang == 'Kr'
                                    ? tutorial2.experience.three.kr
                                    : tutorial2.experience.three.en
                            }}
                        </h3>
                        <div class="textarea-container">
                            <textarea spellcheck="false" class="kor"></textarea>
                            <textarea class="placeholder kor">
이미지폰트</textarea
                            >
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="side-title">
                <h1>Tutorial ➌</h1>
            </div>
            <div class="tutorial-content">
                <div class="directive">
                    <h2>
                        {{
                            this.lang == 'Kr'
                                ? tutorial3.directive.h2.kr
                                : tutorial3.directive.h2.en
                        }}
                    </h2>
                    <h6>
                        {{
                            this.lang == 'Kr'
                                ? tutorial3.directive.h6.kr
                                : tutorial3.directive.h6.en
                        }}
                    </h6>
                </div>
                <div class="experience">
                    <div class="experience__module  experience__module--img">
                        <h3>
                            {{
                                this.lang == 'Kr'
                                    ? tutorial3.experience.two.kr
                                    : tutorial3.experience.two.en
                            }}
                        </h3>
                        <div
                            class="textarea-container img-container smile-container"
                        >
                            <textarea spellcheck="false" class="kor"></textarea>
                            <textarea
                                class="placeholder kor img"
                                v-model="tutorial3.experience.two.placeholder"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="side-title">
                <h1>Tutorial ➍</h1>
            </div>
            <div class="tutorial-content">
                <div class="directive">
                    <h2>
                        {{
                            this.lang == 'Kr'
                                ? tutorial3.directive.h2.kr
                                : tutorial3.directive.h2.en
                        }}
                    </h2>
                    <h6>
                        {{
                            this.lang == 'Kr'
                                ? tutorial3.directive.h6.kr
                                : tutorial3.directive.h6.en
                        }}
                    </h6>
                </div>
                <div class="experience">
                    <div class="experience__module experience__module--img">
                        <h3>
                            {{
                                this.lang == 'Kr'
                                    ? tutorial3.experience.one.kr
                                    : tutorial3.experience.one.en
                            }}
                        </h3>
                        <div
                            class="textarea-container img-container heart-container"
                        >
                            <textarea spellcheck="false" class="kor"></textarea>
                            <textarea
                                class="placeholder kor img"
                                v-model="tutorial3.experience.one.placeholder"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
export default {
    props: {
        lang: {
            default: 'Kr',
        },
    },
    data() {
        return {
            tutorial1: {
                directive: {
                    h2: {
                        kr: `키보드의 한/영 전환키를 눌러 ‘영어’로 바꾼뒤 아래의 지시문을 따라해보세요.`,
                        en: `Press the Korean/English switch key on the keyboard to change the language to ‘English.’ And follow the instructions below.`,
                    },
                    h6: {
                        kr: `* 영어 알파벳은 ‘불규칙 흑백 패턴 음영’으로 구성되어있습니다. 소문자는 가장 밝은 ‘a’ 부터 가장 어두운 ‘z’ 까지이며, 대문자는 ‘A’ 부터 ‘M’까지 연결되는 불규칙 패턴이 나타납니다. ‘N’ 부터 ‘Z’ 까지는 특수한 불규칙 패턴으로 구성되어 있습니다. 패턴을 염두하여 멋진 흑백 글자 또는 이미지를 표현해 보세요!`,
                        en: `*The English alphabets consist of ‘irregular black and white pattern shading.’ Lowercase letters are arranged from the lightest ‘a’ to the darkest ‘z’, and the uppercase letters appear in an irregular pattern connected from ‘A’ to ‘M’. ‘N’ to ‘Z’ comprise special irregular patterns. Keep the patterns in mind and try to express yourself with cool black-and-white text or images!`,
                    },
                },
                experience: {
                    one: {
                        kr: `1. 소문자로 “hello world”를 타이핑 하세요.`,
                        en: `1. Type “hello world” in lowercase.`,
                    },
                    two: {
                        kr: `2. 쉬프트 키와 함께 “WITH ASSY” 를 타이핑 하세요.`,
                        en: `2. Type “WITH ASSY” with the shift key.`,
                    },
                    three: {
                        kr: `3. 소문자 ‘a’ 부터 ‘z’ 까지 순서대로 타이핑 하세요.`,
                        en: `3. Type in the order from lowercase ‘a’ to ‘z’.`,
                    },
                    four: {
                        kr: `4. 대문자 ‘A’ 부터 ‘Z’까지 순서대로 타이핑 하세요.`,
                        en: `4. Type in order from capital ‘A’ to ‘Z’.`,
                    },
                },
            },
            tutorial2: {
                directive: {
                    h2: {
                        kr: `키보드의 한/영 전환키를 눌러 ‘영어’로 바꾼뒤 아래의 지시문을 따라해보세요.`,
                        en: `Press the Korean/English switch key on the keyboard to change the language to ‘English.’ And follow the instructions below.`,
                    },
                    h6: {
                        kr: `* 한글은 칼라 팔레트(자음)와 규칙 흑백 패턴 음 영(모음) 으로 구성되어있습니다. 자음만 사용하 면 색상을 표현할 수 있고, 모음만 사용하면 흑백 음영을 표현할 수 있습니다. 자음과 모음을 함께 사용하면 칼라 와 흑백 음영이 조합되어 ‘칼라 음 영 패턴’을 표현할 수 있습니다.`,
                        en: `* Hangeul (Korean letters) consists of a color palette (constants) and regular black-and-white pattern shades (vowels). Using only constant, you can express colors. And using only vowels, you can express black-and-white shades. When you use constants and vowels together, color and black-and-white shades are combined to express a ‘color shade pattern’.`,
                    },
                },
                experience: {
                    one: {
                        kr: `1. 한글의 자음 “ᄋᄆᄌᄑᄐ”를 타이핑 하세요.`,
                        en: `1. Type the Hangeul constant “ᄋᄆᄌᄑᄐ”.`,
                    },
                    two: {
                        kr: `2. 한글의 모음 “ᅵᅵᅵᅩᅳ”를 타이핑 하세요.`,
                        en: `2. Type the Hangeul vowel “ᅵᅵᅵᅩᅳ”.`,
                    },
                    three: {
                        kr: `3. 한글의 자음과 모음을 함께 사용하여 “이미지폰트”를 타이핑 하세요.`,
                        en: `3. Type the “image font” using the Hangeul constants and vowels together.`,
                    },
                },
            },
            tutorial3: {
                directive: {
                    h2: {
                        kr: `아래의 이미지를 따라 타이핑 해보세요.`,
                        en: `Follow after the images and try to type.`,
                    },
                    h6: {
                        kr: `* 한글은 칼라 팔레트(자음)와 규칙 흑백 패턴 음 영(모음) 으로 구성되어있습니다. 자음만 사용하 면 색상을 표현할 수 있고, 모음만 사용하면 흑백 음영을 표현할 수 있습니다. 자음과 모음을 함께 사용하면 칼라 와 흑백 음영이 조합되어 ‘칼라 음 영 패턴’을 표현할 수 있습니다.`,
                        en: `* Hangeul (Korean letters) consists of a color palette (constants) and regular black-and-white pattern shades (vowels). Using only constant, you can express colors. And using only vowels, you can express black-and-white shades. When you use constants and vowels together, color and black-and-white shades are combined to express a ‘color shade pattern’.`,
                    },
                },
                experience: {
                    one: {
                        kr: `1. 아래의 하트 이미지를 타이핑 하세요.`,
                        en: `1. Type the heart image below.`,
                        placeholder: `111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111444444111114444441111111111111111114444441111144444411111111111111114433333344144333333441111111111111144333333441443333334411111111111144331111334444433333333441111111111443311113333433333333334411111111114433113333333333333333344111111111144331133333333333333333441111111111443333333333333333333334411111111114433333333333333333333344111111111111443333333333333333344111111111111114433333333333333333441111111111111111443333333333333441111111111111111114433333333333334411111111111111111111443333333334411111111111111111111114433333333344111111111111111111111111443333344111111111111111111111111114433333441111111111111111111111111111443441111111111111111111111111111114434411111111111111111111111111111111411111111111111111111111111111111114111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111`,
                    },
                    two: {
                        kr: `2. 아래의 스마일 이미지를 타이핑 하세요.`,
                        en: `1. Type the smile image below.`,
                        placeholder: `2222222222222222222222222222222222222222222222221111222222222222221111111122222222222111111111122222222211111111111122222221111111111111122222211112111121111222221111121111211111222211111111111111112222111111111111111122221112222222222111222221112222222211122222211112222221111222222211112222111122222222211111111112222222222221111112222222222222221111222222222222222222222222222222222222222222222222`,
                    },
                    three: {
                        kr: `3. 아래의 파이프 이미지를 타이핑 하세요.`,
                        en: ``,
                        placeholder: `22222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222225555555222222222222222222225222222222555555555222222222225555555555222222225555555552222222225555555522222222222255555555522222225555555222222222222222555555555222222555555222222222222222225555555552222555555522222222222222222255555555522255555552222222222222222222555555555225555555222222222222222222225555555555555555522222222222222222222225555555555555552222222222222222222222255555555555552222222222222222222222222255555555555222222222222222222222222222225555555522222222222222222222222222222222555552222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222`,
                    },
                },
            },
            output: null,
            options: {
                licenseKey: '8CB33685-E7994806-B12A88A1-E0DF4E53',
                overflow: true,
            },
        };
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
$spot-color: rgb(240, 240, 240);

section {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    &:first-of-type {
        padding-top: 0;
    }
    &:nth-of-type(2n) {
        background: $spot-color;
    }
}
.tutorial-content {
    width: 85%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    .directive {
        display: flex;
        margin-bottom: 4rem;
        h2 {
            width: 50%;
            line-height: 1.5;
            word-break: keep-all;
            padding-right: 1rem;
        }
        h6 {
            width: 50%;
            font-size: 0.7rem;
            line-height: 1.5;
            color: gray;
        }
    }

    .experience {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        &__module {
            width: 46%;
            margin-bottom: 2rem;
            margin-right: 2rem;
            h3 {
                margin-bottom: 0.5rem;
            }
            &--img {
                width: 100%;
                margin-right: 0;
            }
        }

        .long {
            width: 100%;
        }
    }
}

.textarea-container {
    position: relative;
    display: flex;
    // height: 2.7rem;
    &.long {
        // height: 4.5rem;
        textarea {
            height: 100%;
            line-height: 0.7;
        }
    }
    textarea {
        background: transparent;
        font-size: 2.5rem;
        height: 100%;
        padding: 0.5rem;
        font-size: 2.5rem;
        font-family: assy;
        position: relative;
        line-height: 0.7;

        z-index: 2;

        &.placeholder {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            padding: 0.5rem;
            font-size: 2.5rem;
            line-height: 1;
            font-family: assy;
            opacity: 0.2;
            border-color: white;
            line-height: 0.7;
        }
        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.img-container {
    height: 24rem;
    font-size: 1.52vw;

    textarea {
        height: 100%;
        word-break: break-all;
        font-size: inherit;
        &.placeholder {
            font-size: inherit;
        }
    }
}

.heart-container,
.smile-container,
.pipe-container {
    width: 100%;
}

.heart-container {
    font-size: 3.21vw;
    height: 77vw;
}

.smile-container {
    font-size: 5.7vw;
    height: 81vw;
}

.pipe-container {
    font-size: 3vw;
    height: 54vw;
}

.fp-tableCell {
    border: 2px solid red;
}

@media all and (max-width: 1200px) {
    .smile-container {
        font-size: 5.6vw;
    }
}

@media screen and (max-width: 500px) {
    .side-title {
        width: 100%;
        margin-bottom: 0.5rem;
    }
    .tutorial-content {
        width: 100%;
        .directive {
            width: 100%;
            flex-wrap: wrap;
            margin-bottom: 2rem;
            h2,
            h6 {
                width: 100%;
                margin-bottom: 0.5rem;
                padding-right: 0;
            }
        }

        .experience {
            &__module {
                width: 100%;
                margin-right: 0;
                h3 {
                    line-height: 1.4;
                }
            }
        }
    }

    .textarea-container {
        &.long {
            height: 6.5rem;
        }
    }

    .img-container {
        textarea {
            font-size: inherit;
        }
        &.heart-container {
            font-size: 3.5vw;
            height: 21rem;
        }

        &.smile-container {
            width: 100%;
            font-size: 6.21vw;
            height: 22.5rem;
        }

        &.pipe-container {
            width: 100%;
            font-size: 3.2vw;
            height: 14.75rem;
        }
    }
}
</style>
